#menuWrapperMobile{
  position: absolute;
  width: 0px;
  height: 0px;
  transition: all 1s;
}

#menuMobile{
  z-index: 9999; 

  background-color: white;
  
}

#menuWrapperMobile.notSelected{
  width: 0px;
  height: 0px;
}

#menuWrapperMobile.selected{
  width: 100%;
  height: 100%;
}

#menuMobile li{
  list-style-type: none;
  padding: 3px 15px 3px 20px;
}

#menuMobile a{
  text-decoration: none;
  color: black;
}

#contentMobile{
  flex :  1;
}

#homeMobile{
  margin-top: 10vh;
}

#homeMobile img{
  width: 30vw;
  margin-left: 25vw; 
}