body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#mainContainer{
  display: flex;
  font-size: 12px;
  font-family: Verdana,Geneva,sans-serif,"websafe";
}

#menu{
  width: 15vw;
  margin-left : 5vw;
  margin-top: 10vh;
  z-index: 9999; 
  position: fixed;
}

#menu li{
  list-style-type: none;
  padding: 3px 15px 3px 20px;
}

.passiveLink{
  padding-left: 0px;
  transition: padding-left 0.5s; /* Safari */
}
.passiveLink:hover{
  padding-left: 15px;
}

.activeLink{
  padding-left: 15px;
}

#menu a{
  text-decoration: none;
  color: black;
}

#content{
  flex :  1 1;
  width: 70vw;
  margin-left: 35vw;
}

#category img{
  display: block;
  max-width : 40vw;
  max-height: 80vh;
  margin-top: 10vh;
}

#home{
  margin-top: 10vh;
}

#home img{
  width: 20vw;
  margin-left: 2vw; 
}

#homeText{
  max-width: 30vw;
}

#expos{
  margin-top: 10vh;
}

#expos ul{
  margin-left: 10vw;
}

#actus img{
  display: block;
  max-width : 40vw;
  max-height: 80vh;
  margin-top: 10vh;
}
#menuWrapperMobile{
  position: absolute;
  width: 0px;
  height: 0px;
  transition: all 1s;
}

#menuMobile{
  z-index: 9999; 

  background-color: white;
  
}

#menuWrapperMobile.notSelected{
  width: 0px;
  height: 0px;
}

#menuWrapperMobile.selected{
  width: 100%;
  height: 100%;
}

#menuMobile li{
  list-style-type: none;
  padding: 3px 15px 3px 20px;
}

#menuMobile a{
  text-decoration: none;
  color: black;
}

#contentMobile{
  flex :  1 1;
}

#homeMobile{
  margin-top: 10vh;
}

#homeMobile img{
  width: 30vw;
  margin-left: 25vw; 
}
